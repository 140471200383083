import { DynamicHeightContainer } from "@evidenceprime/react-components";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useImperativeHandle, useState } from "react";

const ExpandableCard = ({
  children,
  className,
  contentClassName,
  forwardRef,
  onTransitionEnd,
  title,
  toggleRef,
  initiallyExpanded = false,
  renderPrintableVersion = true,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const [scrollToSectionAfterTransisionEnded, setScrollToSectionAfterTransitionEnded] =
    useState("");

  const scrollToSection = useCallback((section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  }, []);

  useImperativeHandle(toggleRef, () => ({
    openAndScrollTo: (section) => {
      if (!expanded) {
        setExpanded(true);
        setScrollToSectionAfterTransitionEnded(section);
      } else {
        scrollToSection(section);
      }
    },
  }));

  const _onTransitionEnd = useCallback(() => {
    if (!_.isEmpty(scrollToSectionAfterTransisionEnded)) {
      scrollToSection(scrollToSectionAfterTransisionEnded);
    }
    _.isFunction(onTransitionEnd) && onTransitionEnd();
    setScrollToSectionAfterTransitionEnded("");
  }, [scrollToSectionAfterTransisionEnded, onTransitionEnd, scrollToSection]);

  return (
    <React.Fragment>
      {renderPrintableVersion && <div className="print-only">{children}</div>}
      <div className="print-hidden">
        <DynamicHeightContainer
          ref={forwardRef}
          className={className}
          onTransitionEnd={_onTransitionEnd}
        >
          <div
            className={`
          cursor-pointer
          expand-toggle
          expandable-card
          hover:opacity-75
          px-3 
          py-2
          relative
          text-white
        ${expanded ? "expanded" : "collapsed"}`}
            ref={toggleRef}
            onClick={() => setExpanded(!expanded)}
          >
            {title}
          </div>
          {expanded && (
            <div className={`roboto expandable-card__content p-5 ${contentClassName}`}>
              <div className="overflow-x-auto">{children}</div>
              <div className="collapse-arrow">
                <button className="collapse-arrow__button" onClick={() => setExpanded(false)} />
              </div>
            </div>
          )}
        </DynamicHeightContainer>
      </div>
    </React.Fragment>
  );
};

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  onTransitionEnd: PropTypes.func,
  forwardRef: PropTypes.node,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

ExpandableCard.defaultProps = {
  className: "",
  contentClassName: "",
  onTransitionEnd: null,
  tooltip: null,
  forwardRef: null,
};

export default ExpandableCard;
