import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import TalkWithProfessionalSvg from "../../assets/images/talk_with_professional.svg";
import WhatCanYouDoSvg from "../../assets/images/what_can_you_do.svg";
import ExpandableCard from "../common/ExpandableCard";
import PLRTextSection from "./PLRTextSection";
import { PlrTextContent } from "./WhyThisRecommendation";

type WhatDoesThisMeanForYouProps = {
  whatCanYouDo?: PlrTextContent;
  speakWithHCProfessional?: PlrTextContent;
  toggleRef: HTMLDivElement;
};

function WhatDoesThisMeanForYou({
  whatCanYouDo,
  speakWithHCProfessional,
  toggleRef,
}: WhatDoesThisMeanForYouProps) {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={t("labels.whatDoesThisMeanForYou")}
      tooltip={t("tooltips.whatDoesThisMeanForYou")}
      contentClassName="px-1"
      toggleRef={toggleRef}
      initiallyExpanded
    >
      <div className="mx-4">
        {!_.isEmpty(whatCanYouDo?.content) && (
          <PLRTextSection
            icon={<img src={WhatCanYouDoSvg} alt="" />}
            title={t("labels.whatCanYouDo")}
            text={whatCanYouDo?.content || ""}
          />
        )}
        {!_.isEmpty(speakWithHCProfessional?.content) && (
          <PLRTextSection
            icon={<img src={TalkWithProfessionalSvg} alt="" />}
            title={t("labels.speakWithHCProfessional")}
            text={speakWithHCProfessional?.content || ""}
            additionalParts={speakWithHCProfessional?.additionalParts}
          />
        )}
      </div>
    </ExpandableCard>
  );
}

export default WhatDoesThisMeanForYou;
