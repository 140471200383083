import React from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../lib/custom_hooks";
import Search from "../common/Search";
import SubHeader from "../common/SubHeader";

const SearchPage = () => {
  const { t } = useTranslation();
  const { searchQuery: initialSearchQuery, onSearch } = useSearch();

  return (
    <div>
      <SubHeader title={t("labels.search")} />
      <div className="max-w-screen-xl mx-auto mt-12 px-4">
        <Search
          className="font-transat block mx-auto main-page-search-container"
          inputClassName="w-full bg-white main-page"
          searchQuery={initialSearchQuery}
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};

export default SearchPage;
