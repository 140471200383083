import _ from "lodash";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import gridIcon from "../../assets/grid-icon.svg";
import TestingImg from "../../assets/images/canpcc-testing-icon.png";
import HealthCareServicesAndSystemsImg from "../../assets/images/canpcc-healthcare-services-icon.png";
import PediatricAndAdolescentTopicsImg from "../../assets/images/canpcc-pediatric-icon.png";
import ClinicalInterventionsImg from "../../assets/images/canpcc-pharm-icon.png";
import PreventionImg from "../../assets/images/canpcc-prevention-icon.png";
import NeurologicalAndPsychiatricTopicsImg from "../../assets/images/canpcc-neuro-icon.png";
import listIcon from "../../assets/list-icon.svg";
import canpccLogo from "../../assets/logos/canpcc_main.png";
import { useSearch } from "../../lib/custom_hooks";
import Search from "../common/Search";
import Footer from "../layouts/Footer";
import OurPageInNumbers from "../statistics/OurPageInNumbers";

interface ILinkToSubpage {
  text: ReactNode;
  to: string;
  className?: string;
}

const LinkToSubpage = ({ text, to, className }: ILinkToSubpage) => (
  <Link className={`${className ?? ""} p-3 text-center block w-full relative`} to={to}>
    {text}
  </Link>
);

const TOPICS = [
  { key: "prevention", image: PreventionImg },
  { key: "testing", image: TestingImg },
  
  {
    key: "clinical_interventions",
    image: ClinicalInterventionsImg,
  },
  { key: "neurological_and_psychiatric_topics", image: NeurologicalAndPsychiatricTopicsImg },
  { key: "pediatric_and_adolescent_topics", image: PediatricAndAdolescentTopicsImg },
  {
    key: "health_care_services_and_systems",
    image: HealthCareServicesAndSystemsImg,
  },
];

const MainPage = () => {
  const { t, i18n } = useTranslation("");

  return (
    <div className="main-page-container relative flex flex-col">
      <MainPageTopBar />
      <OurPageInNumbers />
      <div id="what-can-you-do-on-this-page">
        <h2>{t("labels.see_our_detailed_video")}</h2>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg mb-24 video-player">
          <iframe
            width="100%"
            loading="lazy"
            src={
              i18n.language === "fr"
                ? "https://www.youtube-nocookie.com/embed/by5Py4BEHwE?si=5IQ9lyL-_6zIKhw1"
                : "https://www.youtube-nocookie.com/embed/rG6F1NXIiAU?si=rtgwKfFtgEkQJeb-"
            }
            title="RecMaps"
            className="mx-auto"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const MainPageTopBar = () => {
  const { t } = useTranslation();
  const { searchQuery: initialSearchQuery, onSearch } = useSearch();

  return (
    <div className="main-page-container__top flex flex-col flex-grow  ">
      <div className="mx-auto mt-8 md:mt-16 mb-8 p-2 flex flex-col md:flex-row items-center">
        <img
          className="w-32 mb-8 md:mb-0 tailwind-hidden md:block"
          src={canpccLogo}
          alt="COVID19 logo"
        />
        <div className="md:ml-16">
          <div className="app-subtitle text-3xl font-transat tailwind-hidden md:block">
            {t("app.mainPageSubtitle")}
          </div>
          <div
            className="font-semibold text-center text-3xl m-6 text-red-200 block md:hidden"
            dangerouslySetInnerHTML={{ __html: t("app.mainPageMobileWelcome") }}
          />

          <div className="text-center block md:hidden">{t("app.mainPageMobileDescription")}</div>
          <Search
            className="font-transat block mx-auto main-page-search-container"
            inputClassName="w-full bg-white main-page"
            searchQuery={initialSearchQuery}
            onSearch={onSearch}
          />
        </div>
      </div>
      <div className="tailwind-hidden md:block">
        <div className="mx-auto flex flex-col align-center max-w-screen-lg px-8">
          <div className="text-base md:text-3xl text-center text-red font-medium mb-2 mt-8">
            {t("app.main_page_header.title")}
          </div>
        </div>
        <h2>{t("app.main_page_header.description")}</h2>
      </div>
      <Tiles>
        <Tile icon={listIcon} title={t("app.main_page_tiles.list_of_recommendations_title")}>
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.list_of_recommendations")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/recommendations"
            text={t(`labels.recommendations_list`)}
          />
        </Tile>
        <Tile icon={listIcon} title={t("app.main_page_tiles.list_of_plrs_title")}>
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.list_of_plrs")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/plrs"
            text={t(`labels.plainLanguageRecommendations`)}
          />
        </Tile>
        <Tile
          icon={gridIcon}
          className="tailwind-hidden lg:block"
          title={t("app.main_page_tiles.grid_title")}
        >
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.grid")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/grid"
            text={t(`labels.recommendations_map`)}
          />
        </Tile>
      </Tiles>
      <Topics />
      <div className="block md:hidden mb-12">
        <h2>{t("app.main_page_header.view_all_recommendations")}</h2>
        <div className="flex">
          <div className="flex-1">
            <LinkToSubpage
              to="/recommendations"
              text={
                <div className="text-center">
                  <img
                    className="w-20 block mx-auto mb-2"
                    src={listIcon}
                    alt={t(`labels.recommendations_list`)}
                  />
                  {t(`labels.recommendations_list`)}
                </div>
              }
            />
          </div>
          <div className="flex-1">
            <LinkToSubpage
              to="/plrs"
              text={
                <div className="text-center">
                  <img
                    className="w-20 block mx-auto mb-2"
                    src={listIcon}
                    alt={t(`labels.plainLanguageRecommendations`)}
                  />
                  {t(`labels.plainLanguageRecommendations`)}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ITiles {
  children: ReactNode;
}

const Tiles = ({ children }: ITiles) => {
  return (
    <div className="tiles mb-12 mt-10 md:flex md:flex-row mx-auto tailwind-hidden justify-center items-stretch flex-wrap">
      {children}
    </div>
  );
};

interface ITile {
  children: ReactNode;
  icon?: string;
  title?: string;
  className?: string;
  childrenClassName?: string;
}

const Tile = ({ title, icon, children, className, childrenClassName }: ITile) => {
  return (
    <div className={`mx-4 tile self-stretch ${className ?? ""}`}>
      <div className="mb-6 md:mb-4 md:w-auto w-full h-full flex flex-col max-w-sm">
        {icon && title && (
          <div className="flex flex-row items-center w-full mb-6">
            <img className="w-20 block" src={icon} alt={title} />
            <div className="text-xl2 font-medium ml-5">{title}</div>
          </div>
        )}
        <div className={`flex flex-col roboto flex-grow ${childrenClassName ?? ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

const Topics = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 mb-12 mx-2 max-w-6xl md:mx-auto">
      <h2>{t("app.view_recommendations_by_topic")}</h2>
      <div className="flex flex-row flex-wrap justify-center gap-4 md:gap-x-40 md:gap-y-8 lg:gap-x-72">
        {_.map(TOPICS, ({ key, image }) => (
          <Topic key={key} title_key={key} image={image} />
        ))}
      </div>
    </div>
  );
};

const Topic = ({ title_key, image }: { title_key: string; image: string }) => {
  const { t } = useTranslation();
  return (
    <div className="topic-image-container">
      <Link to={`/plrs?recommendationIntent=${title_key}`}>
        <span className="block rounded-full overflow-hidden">
          <img src={image} alt={t(`groups.${title_key}`)} />
        </span>
        <span className="block text-center w-full">{t(`groups.${title_key}`)}</span>
      </Link>
    </div>
  );
};

export default MainPage;
