import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type RecommendationTypeSwitchLinkProps = {
  recommendationId: string;
  showAsPlr: boolean;
  gps: boolean;
};

const RecommendationTypeSwitchLink = ({
  recommendationId,
  showAsPlr,
  gps = false,
}: RecommendationTypeSwitchLinkProps) => {
  const { t } = useTranslation();
  const prefix = !showAsPlr ? "plr" : "recommendation";
  const text = gps
    ? !showAsPlr
      ? t("labels.gpsPlrLink")
      : t("labels.gpsLink")
    : !showAsPlr
    ? t("labels.plrLink")
    : t("labels.recommendationLink");

  return (
    <div className="print-hidden">
      <Link
        className="block text-center btn-gradient rounded py-1 px-2 mt-3"
        to={`/${prefix}/${recommendationId}`}
      >
        {text}
      </Link>
    </div>
  );
};

export default RecommendationTypeSwitchLink;
