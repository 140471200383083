import React from "react";
import { useTranslation } from "react-i18next";

type Props = { section: string; gps: boolean; onClick: () => void };

const Bricks = ({ section, gps, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="content-blocks__block hover:opacity-75 shadow bg-white border-red-300 border">
      <button className="py-1 font-semibold px-2" onClick={onClick}>
        {section === "recommendationHeader"
          ? gps
            ? t(`labels.${section}.gps`)
            : t(`labels.${section}.formal`)
          : t(`labels.${section}`)}
      </button>
    </div>
  );
};

export default Bricks;
