import _ from "lodash";
import React, { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  title: string;
  text: string;
  additionalParts?: string[];
};

const PLRTextSection = ({ additionalParts, icon, title, text }: Props) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start mb-6 pb-6 border-b border-gray-100">
      <div className="flex flex-row items-center w-72 flex-shrink-0 mt-12">
        <div className="mr-4 flex-shrink-0">{icon}</div>
        <h3 className="font-semibold text-lg">{title}</h3>
      </div>
      <div className="editor-content bg-white w-full md:bg-gray-100 p-3 mt-4 md:mt-0">
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {!_.isEmpty(additionalParts) && (
          <ul>
            {_.map(additionalParts, (part) => (
              <li>{part}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PLRTextSection;
