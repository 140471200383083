import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedFilterValue } from "../../lib/helpers";

interface IFilterTags {
  currentFilters: Record<string, string>;
  onRemove: (filterKey: string) => void;
}

const filterShortener = (key: string, value: string) => {
  switch (key) {
    case "intendedPopulation":
      return `${value.slice(0, 7)}...${value.slice(-7)}`;
    case "publicationDate":
      return value;
    default:
      return `${value.slice(0, 16)}...`;
  }
};

const useTranslatedFilters = (
  key: string,
  value: string,
  translationFn: (value: string) => string
) => {
  const [short, full] = useMemo(() => {
    const translated = translationFn(value);
    return [translated.length > 19 ? filterShortener(key, translated) : translated, translated];
  }, [key, value, translationFn]);

  return [short, full];
};

const FilterTags = ({ currentFilters, onRemove }: IFilterTags) => {
  const filters = useMemo(
    () => _.omit(currentFilters, ["lng", "searchQuery", "searchMode"]),
    [currentFilters]
  );
  return (
    !_.isEmpty(filters) && (
      <div id="tags-container" className="tags-list flex items-center">
        {_.map(filters, (value, key) => {
          return <Tag key={key} value={value} filterKey={key} onRemove={onRemove} />;
        })}
      </div>
    )
  );
};

interface ITag {
  value: string;
  filterKey: string;
  onRemove: (filterKey: string) => void;
}
const Tag = ({ value, filterKey, onRemove }: ITag) => {
  const { t } = useTranslation();
  const [shortLabel, fullLabel] = useTranslatedFilters(filterKey, `filters.labels.${filterKey}`, t);
  const [shortValue, fullValue] = useTranslatedFilters(
    filterKey,
    value,
    getTranslatedFilterValue(t, filterKey)
  );

  return (
    <div className="tags-list__tag" data-tip={`${fullLabel}: ${fullValue}`}>
      {`${shortLabel}: ${shortValue}`}
      <button className="remove-filter" onClick={() => onRemove(filterKey)}>
        &#215;
      </button>
    </div>
  );
};

export default FilterTags;
