import _, { trim } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { parseDocument } from "../../lib/document_utils";
import BackButton from "../common/BackButton";
import Bricks from "../common/Bricks";
import SubHeader from "../common/SubHeader";

const RecommendationLayout = ({ document: recDoc, children, sections }) => {
  const { t } = useTranslation();

  const scrollTo = useCallback(
    (section) => () => {
      const sectionName = _.camelCase(section.replace("section_", ""));
      if (sectionRefs[sectionName]) {
        sectionRefs[sectionName].current.openAndScrollTo(section);
      } else {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const { sectionComponents, sectionBlocks, sectionRefs } = useMemo(
    () =>
      parseDocument({ ...recDoc, t, onRequestAdolopment: scrollTo("adolopment-form") }, sections),
    [t, recDoc, scrollTo, sections]
  );

  const { informal, gps, "@id": id, recommendationFormality } = recDoc;

  const keywords = useMemo(() => {
    return _.uniq(
      (recDoc.keywords?.[0] || "")
        .split(",")
        .map(trim)
        .concat((recDoc.guideline?.keywords?.[0] || "").split(",").map(trim))
    ).join(", ");
  }, [recDoc]);

  const keywordsCodes = useMemo(() => {
    const interventionCodes = recDoc.intervention
      ?.map((entry) => entry.codes.map((code) => code.name))
      .filter((entry) => entry);
    const populationCodes = recDoc.population
      ?.map((entry) => entry.codes.map((code) => code.name))
      .filter((entry) => entry);
    if (!interventionCodes && !populationCodes) return "";
    return interventionCodes
      .concat(populationCodes)
      .filter((entry) => entry.length > 0)
      .join(", ");
  }, [recDoc]);
  if (_.isEmpty(recDoc.question)) return null;

  return (
    <React.Fragment>
      <Helmet>
        {(!_.isEmpty(keywords) || !_.isEmpty(keywordsCodes)) && (
          <meta name="keywords" content={keywords + keywordsCodes} />
        )}
      </Helmet>
      <div className="bg-gray-100">
        <div className="block md:hidden print-hidden">
          <SubHeader
            title={
              recommendationFormality ? t(`labels.${recommendationFormality}_recommendation`) : ""
            }
          />
        </div>
        <div
          className={
            "relative overflow-hidden h-full page-content font-transat flex flex-col lg:flex-row"
          }
        >
          <div className="tailwind-hidden lg:block my-6 mx-3">
            <BackButton />
          </div>
          <div className="flex flex-row px-2 mt-2 mx-auto sm:mt-5 mb-2 max-w-screen-xl h-full">
            {!_.isEmpty(recDoc.question) &&
              React.cloneElement(children, {
                informal,
                gps,
                sectionComponents,
                recommendationId: id,
              })}
            <div>
              <div className="content-blocks ml-6 tailwind-hidden lg:block print-hidden">
                {_.map(sectionBlocks, (section) => (
                  <Bricks
                    gps={gps}
                    section={section}
                    key={section}
                    onClick={scrollTo(`section_${section}`)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          className="tooltip-container"
          type="dark"
          effect="solid"
          backgroundColor="#031B49"
          clickable
          delayHide={300}
        />
      </div>
    </React.Fragment>
  );
};
export default RecommendationLayout;
