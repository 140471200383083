import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetApi } from "../../lib/api";
import LargeSpinner from "../common/LargeSpinner";
import RecommendationLayout from "../recommendation/RecommendationLayout";
import Plr from "../recommendation/Plr";

const SECTIONS = [
  "recommendationHeader",
  "questionsInfo",
  "recommendationLink",
  "whyThisRecommendation",
  "evidenceSummary",
  "whatDoesThisMeanForYou",
];

const PlrPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [{ data, isLoading, isError }] = useGetApi(`/recommendations/${id}`, {
    searchMode: "plr",
  });
  return (
    <div className="recommendation font-transat">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      {isLoading ? (
        <LargeSpinner />
      ) : (
        data.document && (
          <RecommendationLayout document={data.document} sections={SECTIONS}>
            <Plr />
          </RecommendationLayout>
        )
      )}
    </div>
  );
};

export default PlrPage;
