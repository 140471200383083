import _ from "lodash";
import React from "react";
import AdolopmentForm from "../common/AdolopmentForm";

type RecommendationProps = {
  informal?: boolean;
  gps?: boolean;
  sectionComponents: JSX.Element[];
  recommendationId: string;
};

const Recommendation = ({
  informal,
  gps,
  sectionComponents,
  recommendationId,
}: RecommendationProps) => {
  return _.isEmpty(sectionComponents) || !recommendationId ? null : (
    <div>
      <div>
        {_.map(sectionComponents, (section, key) => {
          return (
            <div key={key} id={`section_${key}`} className="pb-5 section-container">
              {section}
            </div>
          );
        })}
      </div>
      {!informal && !gps && (
        <div className="print-hidden">
          <div className="gradient-separator" />
          <AdolopmentForm recommendationId={recommendationId} />
        </div>
      )}
    </div>
  );
};

export default Recommendation;
