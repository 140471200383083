import React, { useState } from "react";
import { useInterval } from "../../lib/custom_hooks";
import Modal from "./Modal";

interface IFakeLoader {
  isLoading: boolean;
  interval: number;
}

const FakeLoader = ({ isLoading, interval }: IFakeLoader) => (
  <Modal className="fake-loader-modal" modalSize="full" isOpen={isLoading} onClose={() => null}>
    <FakeLoaderInner interval={interval} />
  </Modal>
);

const FakeLoaderInner = ({ interval }: { interval: number }) => {
  const [width, setWidth] = useState(0);
  const progressStatus = document.getElementById("fake-loader__progress-status");
  useInterval(() => {
    if (width < 95) setWidth((width) => (width += 1));
    if (width > 50 && progressStatus) {
      progressStatus.style.color = "#fff";
    }
  }, interval);

  return (
    <div id="fake-loader">
      <div id="fake-loader__progress-bar" style={{ width: `${width}%` }} />
      <div id="fake-loader__progress-status">{width}%</div>
    </div>
  );
};

export default FakeLoader;
