import _ from "lodash";
import { DateTime } from "luxon";
import React, { createRef } from "react";
import QuestionsInfo from "../components/common/QuestionsInfo";
import Coi from "../components/recommendation/Coi";
import Conclusions, { SECTIONS_TO_BE_SEPARATED } from "../components/recommendation/Conclusions";
import EtDPrintout from "../components/recommendation/EtDPrintout";
import ISof from "../components/recommendation/ISof";
import Notes from "../components/recommendation/Notes";
import RecommendationHeader from "../components/recommendation/RecommendationHeader";
import RelatedRecommendations from "../components/recommendation/RelatedRecommendations";
import SummaryOfChoices from "../components/recommendation/SummaryOfChoices";
import WhatDoesThisMeanForYou from "../components/recommendation/WhatDoesThisMeanForYou";
import WhyThisRecommendation from "../components/recommendation/WhyThisRecommendation";

export const parseDocument = (props, sections) => {
  const {
    "@id": recommendationId,
    adolopment,
    authors,
    diagnostic,
    emlLink,
    etdModified,
    etdPrintout,
    evidenceTableLink,
    gps,
    guideline,
    intervention,
    isofData,
    loveLink,
    notes,
    pccModule,
    plr,
    population,
    progress,
    publicationDate,
    question,
    recommendation,
    recommendationFormality,
    references,
    relatedRecommendations,
    research,
    sofSupplemented,
    t,
    transformed,
  } = props;
  if (_.isEmpty(question)) return {};
  const [conclusionsSectionsOrder, implementationConsiderationsOrRemarksOrder] = _.partition(
    recommendation.conclusions?.sectionsOrder,
    (section) => !_.includes(SECTIONS_TO_BE_SEPARATED, section)
  );

  const conclusionsSections = recommendation.conclusions?.sections;
  const conclusionsHasAnyValue = _.some(conclusionsSections, (section) => !_.isEmpty(section));
  const sectionComponents = {};
  const sectionBlocks = [];
  const publicationDateValue =
    publicationDate?.length !== 0
      ? DateTime.fromJSDate(new Date(publicationDate)).toFormat("yyyy-MM-dd")
      : "";
  const clearAndActionable = conclusionsSectionsOrder.splice(0, 1);
  const modifiedConslusionSectionOrder = [...conclusionsSectionsOrder, ...clearAndActionable];

  const sectionRefs = {};
  sections.forEach((section) => {
    switch (section) {
      case "recommendationHeader":
        sectionComponents[section] = (
          <RecommendationHeader
            adolopment={adolopment}
            authors={authors}
            gps={gps}
            gradeCertaintyOfEvidence={_.first(question).gradeCertaintyOfEvidence}
            guideline={guideline}
            intents={pccModule}
            plrRecommendation={plr?.plrRecommendation}
            publicationDate={publicationDateValue}
            recommendation={recommendation}
            recommendationId={recommendationId}
            recommendationFormality={recommendationFormality}
            transformed={transformed}
            whoIsThisFor={plr?.plrData?.whoIsThisFor}
          />
        );
        sectionBlocks.push(section);
        break;
      case "questionsInfo":
        sectionComponents[section] = (
          <QuestionsInfo
            emlLinks={emlLink}
            evidenceTableLink={evidenceTableLink}
            intervention={intervention}
            loveLink={loveLink}
            population={population}
            progress={progress}
            question={question}
            recommendation={recommendation}
          />
        );
        break;
      case "conclusions":
        if (!_.isEmpty(conclusionsSectionsOrder) && conclusionsHasAnyValue) {
          const sectionKey = gps
            ? "goodPracticeStatementCriteria"
            : "justificationAndOtherConsiderations";
          const conclusionsToggleRef = createRef();
          sectionComponents[sectionKey] = (
            <Conclusions
              gps={gps}
              sections={conclusionsSections}
              sectionsOrder={modifiedConslusionSectionOrder}
              toggleRef={conclusionsToggleRef}
            />
          );
          sectionBlocks.push(sectionKey);
          sectionRefs[sectionKey] = conclusionsToggleRef;
        }
        break;
      case "implementationConsiderationsOrRemarks":
        const sectionKey = "additionalInformation";

        const { implementationConsiderationsOrRemarks } = conclusionsSections || {};
        if (
          !_.isEmpty(implementationConsiderationsOrRemarksOrder) &&
          !_.isEmpty(implementationConsiderationsOrRemarks)
        ) {
          const additionalInformationToggleRef = createRef();
          sectionComponents[sectionKey] = (
            <Conclusions
              gps={gps}
              title={
                gps
                  ? t("labels.implementationConsiderations")
                  : t("labels.justificationAndOtherConsiderations")
              }
              sections={recommendation.conclusions.sections}
              sectionsOrder={implementationConsiderationsOrRemarksOrder}
              toggleRef={additionalInformationToggleRef}
            />
          );
          sectionBlocks.push(sectionKey);
          sectionRefs[sectionKey] = additionalInformationToggleRef;
        }
        break;
      case "summaryOfChoices":
        const { sections: assessmentSections } = recommendation.assessmentSummary;
        const hasAnyValue = _.some(assessmentSections, (section) => !_.isEmpty(section));
        if (hasAnyValue) {
          const sofToggleRef = createRef();
          sectionComponents[section] = (
            <SummaryOfChoices
              assessmentSummary={recommendation?.assessmentSummary}
              toggleRef={sofToggleRef}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = sofToggleRef;
        }
        break;
      case "evidenceSummary":
      case "isof":
        if (!_.isEmpty(isofData)) {
          const isofToggleRef = createRef();
          sectionComponents[section] = (
            <ISof
              isDiagnostic={diagnostic}
              isofData={isofData}
              sofSupplemented={sofSupplemented}
              toggleRef={isofToggleRef}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = isofToggleRef;
        }
        break;
      case "iEtd":
        if (!_.isEmpty(etdPrintout)) {
          const ietdToggleRef = createRef();
          sectionComponents[section] = (
            <EtDPrintout
              etdModified={etdModified}
              etdPrintout={etdPrintout}
              toggleRef={ietdToggleRef}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = ietdToggleRef;
        }
        break;
      case "coi":
        if (!_.isEmpty(recommendation.coi)) {
          const coiToggleRef = createRef();
          sectionComponents[section] = <Coi coi={recommendation.coi} toggleRef={coiToggleRef} />;
          sectionBlocks.push(section);
          sectionRefs[section] = coiToggleRef;
        }
        break;
      case "notes":
        if (!_.isEmpty(notes) && !_.isEmpty(references)) {
          const notesToggleRef = createRef();
          sectionComponents[section] = (
            <Notes notes={notes} references={references} toggleRef={notesToggleRef} />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = notesToggleRef;
        }
        break;
      case "relatedRecommendations":
        if (!_.isEmpty(relatedRecommendations)) {
          const relatedRecommendationToggleRef = createRef();
          sectionComponents[section] = (
            <RelatedRecommendations
              toggleRef={relatedRecommendationToggleRef}
              relatedRecommendations={relatedRecommendations}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = relatedRecommendationToggleRef;
        }
        break;
      case "whyThisRecommendation": {
        const {
          additionalInformation,
          benefitsAndHarms,
          whyAGoodPracticeStatement,
          whyThisRecommendation,
        } = plr.plrData || {};
        const whyThisRecommendationToggleRef = createRef();
        const { strength } = recommendation;

        const sectionKey = gps ? "whyThisGoodPracticeStatement" : section;

        const whyThisRecommendationContent = gps
          ? whyAGoodPracticeStatement
          : whyThisRecommendation;

        if (
          _.every(
            [whyThisRecommendationContent, additionalInformation, benefitsAndHarms],
            _.isEmpty
          )
        ) {
          break;
        }

        sectionComponents[sectionKey] = (
          <WhyThisRecommendation
            additionalInformation={additionalInformation}
            benefitsAndHarms={benefitsAndHarms}
            gps={gps}
            gradeStrength={strength}
            research={research}
            sectionKey={sectionKey}
            toggleRef={whyThisRecommendationToggleRef}
            whyThisRecommendation={whyThisRecommendationContent}
          />
        );
        sectionBlocks.push(sectionKey);
        sectionRefs[sectionKey] = whyThisRecommendationToggleRef;

        break;
      }
      case "whatDoesThisMeanForYou":
        const { speakWithHCProfessional, whatYouCanDo } = plr.plrData || {};
        if (_.every([speakWithHCProfessional?.content, whatYouCanDo?.content], _.isEmpty)) break;
        const whatDoesThisMeanForYouToggleRef = createRef();
        sectionComponents[section] = (
          <WhatDoesThisMeanForYou
            whatCanYouDo={whatYouCanDo}
            speakWithHCProfessional={speakWithHCProfessional}
            toggleRef={whatDoesThisMeanForYouToggleRef}
          />
        );
        sectionBlocks.push(section);
        sectionRefs[section] = whatDoesThisMeanForYouToggleRef;
        break;
      default:
        break;
    }
  });
  return {
    sectionComponents,
    sectionBlocks,
    sectionRefs,
  };
};
