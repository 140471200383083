export const CERTAINTY_TYPES = [
  "low",
  "very_low",
  "moderate",
  "high",
  "other_explanation_in_notes",
  "not_stated",
  "unclear",
  "very_low_to_low",
  "low_to_moderate",
  "moderate_to_high",
  "missing",
  "no_included_studies",
] as const;

export type TCertaintyValue = (typeof CERTAINTY_TYPES)[number];

export type TQuestion = {
  gradeStrength: string;
  gradeCertaintyOfEvidence: string;
};

export type TRecommendationDirection = "positive" | "negative" | "none" | "neutral";
export type TGradeStrength = "strong" | "conditional" | "weak" | "";

export type TRecommendationListItem = {
  "@id": string;
  recommendation: string;
  ages: string[];
  gps: boolean;
  publicationDate: string;
  informal: boolean;
  recommendationFormality: "formal" | "informal" | "gps" | "research";
  guidelineCitation: string;
  transformed: boolean;
  adapted: boolean;
  adolopment?: boolean;
  guideline?: {
    link: string;
    title: string;
  };
  guidelineSource?: string;
  gradeCertaintyOfEvidence?:
    | "low"
    | "very_low"
    | "moderate"
    | "high"
    | "other_explanation_in_notes"
    | "not_stated"
    | "unclear";
  gradeCertaintyOfEvidenceLabel?: string;
  recommendationDirection?: TRecommendationDirection;
  gradeStrength?: TGradeStrength;
};

export type TProgress = {
  plus: TPlus[];
  progress: TProgressValues[];
  progressPlus: string[];
};

export type TPlus =
  | "personalCharacteristics"
  | "featuresOfRelationships"
  | "timeDependentRelationships";

export type TProgressValues =
  | "placeOfResidence"
  | "raceEthnicityCultureOrLanguage"
  | "occupation"
  | "genderOrSex"
  | "religion"
  | "education"
  | "ses"
  | "socialCapital";
