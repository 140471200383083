import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AdditionalInformationSvg from "../../assets/images/additional_information.svg";
import BenefitsAndHarmsSvg from "../../assets/images/benefits_and_harms.svg";
import WhySvg from "../../assets/images/why.svg";
import ExpandableCard from "../common/ExpandableCard";
import PLRTextSection from "./PLRTextSection";
import { getRecommendationText } from "../../lib/helpers";

export type PlrTextContent = {
  content?: string;
  additionalParts?: string[];
};

type WhyThisRecommendationProps = {
  gps: boolean;
  research: boolean;
  additionalInformation?: PlrTextContent;
  benefitsAndHarms?: PlrTextContent;
  gradeStrength: string;
  sectionKey: "whyThisRecommendation" | "whyThisGoodPracticeStatement";
  toggleRef: HTMLDivElement;
  whyThisRecommendation?: PlrTextContent & {
    selectedOption?: string;
  };
};

function WhyThisRecommendation({
  additionalInformation,
  benefitsAndHarms,
  gps,
  research,
  gradeStrength,
  sectionKey,
  toggleRef,
  whyThisRecommendation,
}: WhyThisRecommendationProps) {
  const { t } = useTranslation();

  const recommendationOption = useMemo(() => {
    return getRecommendationText(t, gradeStrength);
  }, [t, gradeStrength]);

  return (
    <ExpandableCard
      title={t(`labels.${sectionKey}`)}
      tooltip={t(`tooltips.${sectionKey}`)}
      contentClassName="px-1"
      toggleRef={toggleRef}
      initiallyExpanded
    >
      <div className="mx-4">
        {!_.isEmpty(whyThisRecommendation?.content) && (
          <PLRTextSection
            icon={<img src={WhySvg} alt="" />}
            title={
              gps
                ? t("labels.whyAGoodPracticeStatement")
                : research
                ? t("labels.whyResearch")
                : recommendationOption
                ? t("labels.why", { selectedOption: recommendationOption })
                : ""
            }
            text={whyThisRecommendation?.content || ""}
          />
        )}
        {!_.isEmpty(additionalInformation?.content) && (
          <PLRTextSection
            icon={<img src={AdditionalInformationSvg} alt="" />}
            title={t("labels.additionalInformation")}
            text={additionalInformation?.content || ""}
          />
        )}
        {!_.isEmpty(benefitsAndHarms?.content) && (
          <PLRTextSection
            icon={<img src={BenefitsAndHarmsSvg} alt="" />}
            title={t("labels.benefitsAndHarms")}
            text={benefitsAndHarms?.content || ""}
          />
        )}
      </div>
    </ExpandableCard>
  );
}

export default WhyThisRecommendation;
