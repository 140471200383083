import _ from "lodash";
import React, { ReactNode } from "react";

type Props = {
  sectionComponents: ReactNode[];
};

const Plr = ({ sectionComponents }: Props) => {
  return (
    <div>
      {_.map(sectionComponents, (section, key) => {
        return (
          <div key={key} id={`section_${key}`} className="pb-5 section-container">
            {section}
          </div>
        );
      })}
    </div>
  );
};

export default Plr;
