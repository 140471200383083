import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useShowAsPlr } from "../../lib/custom_hooks";
import { guidelineShape } from "../../lib/data_shapes";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import Box from "../common/Box";
import Certainty from "../common/Certainty";
import Intents from "../common/Intents";
import RecommendationIcon from "../common/RecommendationIcon";
import Scores from "../common/Scores";
import Transformed from "../common/Transformed";
import TypeHeader, { RECOMMENDATION_FORMALITIES } from "../common/TypeHeader";
import GuidelineHeader from "./GuidelineHeader";
import RecommendationTypeSwitchLink from "./RecommendationTypeSwitchLink";

const RecommendationHeader = ({
  recommendationId,
  authors,
  gps,
  gradeCertaintyOfEvidence,
  guideline,
  intents,
  plrRecommendation,
  publicationDate,
  recommendation,
  recommendationFormality,
  transformed,
  whoIsThisFor,
}) => {
  const { t } = useTranslation();
  const showAsPlr = useShowAsPlr();

  const { strength: gradeStrength, direction: recommendationDirection } = recommendation ?? {};

  const recommendationType = useMemo(() => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    return getRecommendationText(t, gradeStrength);
  }, [t, gradeStrength]);

  if (!recommendation) return null;

  const recommendationContent =
    showAsPlr && !_.isEmpty(plrRecommendation) ? plrRecommendation : recommendation.value;

  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="flex-grow">
          <TypeHeader
            className="tailwind-hidden md:flex mb-3 mt-1"
            recommendationFormality={showAsPlr ? "plr" : recommendationFormality}
            type="recommendation"
            gps={gps}
            plr={showAsPlr}
          />
        </div>
      </div>
      <Box className="bg-red-500">
        {!gps && <GuidelineHeader guideline={guideline} />}
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow roboto link-finder font-standard">
            <div className={`bg-red-300 p-5 ${!showAsPlr ? "h-full" : ""}`}>
              {!gps && <Intents intents={intents} />}
              <div className="mr-6" dangerouslySetInnerHTML={{ __html: recommendationContent }} />
            </div>
            {showAsPlr && whoIsThisFor && !_.isEmpty(whoIsThisFor.content) && (
              <React.Fragment>
                <div className="mt-4 p-5">
                  <div className="plr-who-is-this-for">
                    <p className="font-semibold">{t("recommendation.whoIsThisFor")}</p>
                    <ul className="list-disc pl-5">
                      <li>{whoIsThisFor.content}</li>
                      {_.map(whoIsThisFor.additionalParts, (part, idx) => (
                        <li key={idx}>{part}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {recommendation.strength && (
                  <div className="mt-4 p-5">{t("tooltips.strength")}</div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="flex flex-col md:mt-0 p-5 bg-gray-1200 md:max-w-sm">
            <div className="strength-certainty-column mb-4">
              {gradeCertaintyOfEvidence && (
                <div className="bordered-info-box flex">
                  <div>
                    <div className="flex flex-row items-center">
                      <div className="font-semibold mr-4">
                        <div>{t("recommendation.certainty_of_evidence")}</div>
                      </div>
                      <Transformed transformed={transformed} />
                    </div>
                    <Certainty value={gradeCertaintyOfEvidence} />
                  </div>
                  <div
                    data-tip={t("tooltips.certainty_of_evidence")}
                    className="print-hidden info-sign"
                  >
                    <span className="info-sign__element ">i</span>
                  </div>
                </div>
              )}
              {recommendation.strength && (
                <div className="bordered-info-box flex">
                  <div>
                    <div className="font-semibold">
                      {t("recommendation.recommendation_strength")}
                    </div>
                    <div>
                      <RecommendationIcon
                        textClasses="font-normal"
                        recommendationText={recommendationText}
                        recommendationType={recommendationType}
                      />
                    </div>
                  </div>
                  <div data-tip={t("tooltips.strength")} className="print-hidden info-sign">
                    <span className="info-sign__element ">i</span>
                  </div>
                </div>
              )}
              {authors && (
                <div className="bordered-info-box">
                  <p className="font-semibold">{t("recommendation.authors")}</p>
                  <p dangerouslySetInnerHTML={{ __html: authors }} />
                </div>
              )}
              {publicationDate.length !== 0 && (
                <div className="bordered-info-box">
                  <p className="font-semibold">{t("labels.pubDate")}</p>
                  <p>{publicationDate}</p>
                </div>
              )}
              <Scores agreeScore={guideline.agreeScore} />
            </div>

            <RecommendationTypeSwitchLink
              recommendationId={recommendationId}
              showAsPlr={showAsPlr}
              gps={gps}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

RecommendationHeader.propTypes = {
  recommendation: PropTypes.object.isRequired,
  guideline: guidelineShape.isRequired,
  gradeStrength: PropTypes.string,
  gps: PropTypes.bool,
  authors: PropTypes.string,
  publicationDate: PropTypes.string,
  transformed: PropTypes.bool,
  intents: PropTypes.arrayOf(PropTypes.string),
  recommendationFormality: PropTypes.oneOf(RECOMMENDATION_FORMALITIES).isRequired,
};

RecommendationHeader.defaultProps = {
  gradeStrength: "",
  gps: false,
  intents: [],
  transformed: false,
};

export default RecommendationHeader;
